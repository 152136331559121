<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title
          ><h2>Add Payment</h2>

          <h4 class="ml-16 red--text">
            {{ invoice.invoice_code }}
          </h4></v-card-title
        >
        <v-card-text>
          <div class="d-flex">
            <h4 class="mr-5 mb-4">Invoice Amount: {{ invoice.amount }}</h4>
            <h4>
              Invoice Discount:
              {{ invoice.discount }}
            </h4>
          </div>

          <v-divider class="mb-4"></v-divider>
          <v-text-field
            v-model="total_amount"
            label="Total Payable Amount"
            prepend-icon="mdi-cash-multiple"
            readonly
          ></v-text-field>
          <v-text-field
            label="Cash"
            prepend-icon="mdi-cash"
            v-model="form.amount_paid"
            @input="check_cash_input"
          ></v-text-field>

          <v-text-field
            label="Change"
            prepend-icon="mdi-hand-coin"
            :value="change"
            readonly
          ></v-text-field>
          <v-select
            :items="chart_of_accounts"
            item-text="account_title"
            item-value="id"
            prepend-icon=""
            label="Paid To"
            v-model="form.chart_of_account_id"
          ></v-select>
          <v-row>
            <v-col cols="4">
              <span class="mr-2">Payment Date: </span>
              <input type="date" v-model="form.payment_date" class="mb-3"
            /></v-col>

            <v-col>
              <v-text-field
                label="OR Number"
                v-model="form.or_number"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-select
            :items="payment_status"
            prepend-icon=""
            label="Payment Status"
            v-model="form.invoice_status"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="submit" :loading="loading"
            >Submit</v-btn
          >
          <v-btn text @click="$emit('close_payment')">Close</v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  props: ["invoice"],
  data() {
    return {
      loading: false,
      payment_status: ["Paid", "With Balance", "Waiting for Payment"],
      tax_code: ["Tax Exempt", "VAT", "Non-Vat"],
      dialog: true,
      total_amount: this.invoice.balance,
      form: {
        invoice_id: this.invoice.id,
        invoice_status: "Paid",
        amount_paid: 0,
        total_payable_amount: this.invoice.balance,
        balance: "",
        chart_of_account_id: 5,
        payment_date: "",
        tax_code: "Tax Exempt",
        or_number: 0,
      },
    };
  },
  methods: {
    check_cash_input() {
      this.form.invoice_status =
        this.form.amount_paid != this.total_amount ? "With Balance" : "Paid";
    },

    ...mapActions({
      add_payment: "payment/add_payment",
      set_notification: "notification/set_notification",
      get_chart_of_accounts: "chart_of_account/get_chart_of_accounts",
    }),

    async submit() {
      // set balance
      this.form.balance =
        this.form.total_payable_amount - this.form.amount_paid;
      // check fields
      this.loading = true;
      if (this.form.payment_date == "") {
        this.loading = false;
        return alert("Please check all fields!");
      }
      await this.add_payment(this.form)
        .then(() => {
          this.loading = false;
          alert("payment inserted");
          return this.$emit("close_payment");
        })
        .catch((e) => {
          this.loading = false;
          alert(e);
        });
    },
  },
  created() {
    this.form.payment_date = moment().format("Y-MM-DD");
    this.form.amount_paid = this.total_amount;
    if (this.chart_of_accounts.length <= 0) {
      this.get_chart_of_accounts();
    }
  },
  computed: {
    change() {
      let x;
      if (this.amount_paid == 0) x = 0;
      else x = this.total_amount - this.form.amount_paid;
      return x;
    },
    ...mapGetters({
      chart_of_accounts: "chart_of_account/chart_of_accounts",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
